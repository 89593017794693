<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { depositInfoData, timerWheelDisabled } = useDepositStreakData({ immediate: false });

const timerActive = computed(() => depositInfoData.value.secondsToResetStreakCounter);

const buttonName = computed(() => {
	if (timerActive.value) {
		return timerWheelDisabled.value;
	}
	if (depositInfoData.value.canWheel) {
		return t("Spin");
	}
	return t("Buy");
});
const handleWheelOfWinsClick = () =>
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: "wheel_of_wins",
				location: "reward_cabinet"
			});
			if (depositInfoData.value.secondsToResetStreakCounter) {
				navigateTo("/wheel-of-wins");
				return;
			}
			if (depositInfoData.value.canWheel) {
				open("LazyOModalWheelOfWinsPlayWheel");
				return;
			}

			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
</script>
<template>
	<div @click="handleWheelOfWinsClick">
		<div class="icon">
			<NuxtImg
				src="/nuxt-img/wheel-of-wins/wheel/mini-wheel-percent.png"
				width="80"
				height="82"
				loading="lazy"
				quality="100"
				format="webp"
				alt="wheel of wins"
			/>
		</div>
		<div class="content">
			<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("WheelOfWins.Name") }}
			</AText>
			<LazyMWheelOfWinsStepsProgressBar class="wheel-progress" />
			<AText class="progress-text text-caracas" variant="ternopil" as="div">
				<i18n-t keypath="Purchases {key} completed">
					<template #key>
						{{ `${depositInfoData?.currentCountDeposit}/${depositInfoData?.maxNumberOfDeposit}` }}
					</template>
				</i18n-t>
			</AText>
		</div>
		<AButton
			:variant="timerActive ? 'secondary' : 'primary'"
			:modifiers="[timerActive ? 'disabled' : '']"
			class="btn"
			size="s"
		>
			<AText variant="topeka" :modifiers="['uppercase']">{{ buttonName }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}

.btn {
	--a-button-secondary-background: var(--chaozhou);
	--a-button-secondary-disabled-background: var(--chaozhou);
	--a-button-secondary-disabled-color: var(--cannes);
}

.wheel-progress.progress {
	&:deep() {
		background: none;
		padding: 8px 0 4px 0;

		.step {
			padding: 5px 9px;
		}
	}
}
</style>
